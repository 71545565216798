import { Link } from "gatsby"
import React from "react"
import logo from "../../images/lbs.png"
import "./footer.css"

const Footer = () => {
  return (
    <footer>
      <div id="subfooter-container">
        <div id="link-container">
          <h3 id="subfooter-title">Company</h3>
          <nav>
            <ul>
              <li>
                <Link to="/terms-of-service/">Terms of Service</Link>
              </li>
              <li>
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </li>
              <li>
                <a href="/#the-problem">The Problem</a>
              </li>
              <li>
                <a href="/#intro">About</a>
              </li>
              <li>
                <a href="mailto:contact@lifebeyondscreens.com">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
        <div id="link-container">
          <h3 id="subfooter-title">Resources</h3>
          <nav>
            <ul>
              <li>
                <Link to="/vicedrop/">Start Recovery</Link>
              </li>
              <li>
                <a href="https://guide.lifebeyondscreens.com/">
                  Recovery Guide{" "}
                </a>
              </li>
              <li>
                <a
                  href="https://vicedrop.lifebeyondscreens.com/"
                  target="_blank"
                >
                  ViceDrop
                </a>
              </li>
              <li>
                <a href="https://www.reddit.com/r/LifeBeyondScreens/">
                  Community{" "}
                </a>
              </li>
              <li>
                <Link to="/blog/">Blog</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div id="footer-container">
        <Link to="/">
          <img src={logo} alt="Life Beyond Screens Logo" />
        </Link>
        <div id="footer-info">
          <p>
            Life Beyond Screens is <b>not</b> a replacement for medical
            attention and doesn't provide medical advise. Information gathered
            here is solely for educational and informational purposes.
          </p>
          <p>
            Copyright 2022, Life Beyond Screens. You are awesome and matter.
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
